import React from "react";
import "./whous.css";

const About = ({ t }) => {
  return (
    <section className="flex flex-col h-screen xs:h-auto xs:min-h-screen w-full xs:pt-7">
      <article className="flex flex-row w-full h-full xs:grow">
        <aside className="flex flex-col justify-center items-center w-1/2 h-full xs:hidden ">
          <img src="/asset/about-mid.svg" alt="img-whoUs" />
        </aside>
        <div
          className="flex grow flex-col justify-center items-start 
                  h-full w-1/2 
                  pl-6                   
                  px-[25px]
                  lg:text-p_lg md:text-p_md sm:text-p_md xs:text-p_md
                   gap-[20px] 
                   xs:px-[15px]"
        >
          <p className="title-box text-[#391400a3]">{t("nav_tab_1")}</p>

          <p className="lg:text-h2_md md:text-h2_md sm:text-h2_sm xs:text-h2_xs">
            {t("section_who_us_title")}
          </p>
          <p className="title-box-3">{t("section_who_us_description")}</p>
          <p className="font-bold	leading-10	">
            {t("section_who_us_description_2")}
          </p>
          <div className="w-full h-auto flex justify-center items-end mt-6">
            <img
              src="/asset/about-mid.svg"
              alt="img-whoUs"
              className="hidden xs:inline"
              style={{ maxHeight: "120x", width: "200px" }}
            />
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
