import React from "react";
import "./about.css";
const About = ({ t }) => {
  return (
    <section className="flex flex-row h-screen xs:h-auto xs:min-h-screen w-full  ">
      <div
        className="flex flex-col xs:grow justify-center items-start w-1/2 lg:pr-[50px] 
                      sm:w-full xs:w-full xs:justify-start xs:pt-7 xs:px-[15px]"
      >
        <h2 className="title">{t("section_about_us_title")}</h2>
        <p className="subtitle">{t("section_about_us_description_1")}</p>

        <p className="subtitle xs:mt-[30px]">
          {t("section_about_us_description_2")}
        </p>
        <p className="font-bold lg:text-h2_lg md:text-h2_md sm:text-h2_sm xs:text-h2_xs py-[20px] xs:py-[5px]">
          {t("section_about_us_description_3")}
        </p>
        <p className="subtitle" style={{ textAlign: "left" }}>
          {t("section_about_us_description_4")}
          <br />
          {t("section_about_us_description_5")}
        </p>
        <p className="subtitle xs:hidden" style={{ textAlign: "left" }}>
          {t("section_about_us_description_6")}
        </p>
        <div className="w-full h-auto flex justify-center items-end mt-6">
          <img
            src="/asset/about-1-.svg"
            alt="About"
            className="hidden xs:flex"
            style={{ maxHeight: "200px", width: "200px" }}
          />
        </div>
      </div>
      <aside className="h-full  flex flex-col justify-center xs:hidden ">
        <img src="/asset/about-1-.svg" alt="About" className="" />
      </aside>
    </section>
  );
};

export default About;
