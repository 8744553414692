import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowSize from "../hooks/useWindowsSize";

const Nav = () => {
  const { is_mobile, width, height } = useWindowSize();
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();

  const options = [
    { name: t("nav_tab_1") },
    { name: t("nav_tab_2") },
    { name: t("nav_tab_3") },
    { name: t("nav_tab_4") },
    { name: t("nav_tab_5") },
  ];

  const _build = () => {
    return <>{is_mobile ? _createMobileNav() : _createWebNav()}</>;
  };

  const _createMobileNav = () => {
    return (
      <nav
        id="nav-mobile"
        role="navigation"
        aria-label="main navigation"
        className={`flex h-auto w-full
                 xs:flex-col 
                 justify-between gap-4 items-center xs:items-start
                 xs:py-[10px] xs:px-[10px] z-50`}
      >
        <div className="w-full flex flex-row justify-between items-center">
          {_createImage()}
          <select
            className="text-black rounded-lg px-1"
            onChange={handleLanguageChange}
            value={i18n.language}
          >
            <option value="en">English</option>
            <option value="es">Español</option>
          </select>
          <div
            className="w-[48px] h-[48px] p-[4px]"
            onClick={_handleMobileMenuClick}
          >
            <div className="bg-white rounded-md w-full h-full flex flex-col justify-center gap-[3px]">
              <div className="mx-[4px] h-[4px] bg-black/50 rounded-md"></div>
              <div className="mx-[4px] h-[4px] bg-black/50 rounded-md"></div>
              <div className="mx-[4px] h-[4px] bg-black/50 rounded-md"></div>
            </div>
          </div>
        </div>
        {_createMobileOptions()}
      </nav>
    );
  };

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };
  const _createWebNav = () => {
    return (
      <nav
        id="nav-web"
        role="navigation"
        aria-label="main navigation"
        className="flex flex-row w-full h-auto
                  justify-beetwen items-center
                  lg:py-[20px] md:py-[20px] sm:py-[20px]
                  lg:px-[20px] md:px-[18px] sm:px-[16px] 
                  z-50"
      >
        {_createImage()}
        {_createWebOptions()}
        <select
          className="text-black px-2 rounded-lg ml-2"
          onChange={handleLanguageChange}
          value={i18n.language}
        >
          <option value="es">Español</option>
          <option value="en">English</option>
        </select>
      </nav>
    );
  };

  const _createItem = ({ name, onClick }) => {
    return (
      <li
        id="nav-options-item"
        key={name}
        className="h-auto 
        lg:px-[8px] md:px-[8px] sm:px-[6px]
        text-white cursor-pointer 
        hover:text-gray-400 
        hover:bg-gray-800/10        
        transition-all ease-in-out
        lg:text-nav_li_lg  md:text-nav_li_md sm:text-nav_li_sm xs:text-nav_li_md
        "
        onClick={onClick}
      >
        {name}
      </li>
    );
  };

  const _scrollToOption = ({ index }) => {
    console.log(index);
    const moreScrollToMobile =
      index === 1
        ? 2
        : index === 2
          ? 2.5
          : index === 3
            ? 3
            : index === 4
              ? 4
              : 0;
    const moreToDesktopScroll =
      index === 1
        ? 0.85
        : index === 2
          ? 1
          : index === 3
            ? 1
            : index === 4
              ? 1.5
              : 0;

    window.scrollBy({
      top:
        window.innerHeight *
        (is_mobile
          ? index + 1.45 + moreScrollToMobile
          : index + 1.02 + moreToDesktopScroll),
      behavior: "smooth",
    });
  };

  const _createListItem = () => {
    return options.map((option, index) => {
      return _createItem({
        name: option.name,
        onClick: () => _scrollToOption({ index }),
      });
    });
  };

  const _createWebOptions = () => {
    return (
      <>
        <ul
          id="nav-options-web"
          className="flex grow justify-end items-center text-white 
        lg:text-[18px] md:text-[16px] sm:text-[14px]"
        >
          {_createListItem()}
        </ul>
      </>
    );
  };

  const _createMobileOptions = () => {
    return (
      <ul
        id="nav-options-mobile"
        className={`${show ? "flex" : "hidden"} z-50 relative h-[50px] w-full                     
                    flex-col gap-3 xs:h-auto 
                    justify-start items-center 
                    lg:text-[18px] 
                    md:text-[16px] 
                    sm:text-[14px]
                  xs:bg-[#282c31]`}
      >
        {_createListItem()}
      </ul>
    );
  };

  const _handleMobileMenuClick = () => {
    if (!is_mobile) return;
    setShow(!show);
  };

  const _createImage = () => {
    return (
      <img
        id="nav-logo"
        src="/asset/logo.svg"
        alt="logo"
        className="flex lg:h-[40px] md:h-[36px] sm:h-[32px] xs:h-[32px] w-auto 
        "
      />
    );
  };

  return _build();
};

export default Nav;
