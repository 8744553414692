import React from "react";
import Nav from "../../components/nav/Nav";
import useWindowSize from "../../components/hooks/useWindowsSize";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { is_mobile } = useWindowSize();
  const { t } = useTranslation();

  const _build = () => {
    return (
      <div className="flex flex-col justify-center items-center w-full h-screen xs:h-auto xs:min-h-screen">
        <Nav />
        <section
          className="
      flex flex-row xs:grow justify-start items-center
      lg:mt-[-50px] xs:mt-[0px]
      w-full h-full 
      lg:items-start md:items-center sm:items-center"
        >
          {" "}
          {_createAside()}
          {_createIamge()}
        </section>
      </div>
    );
  };

  const _createIamge = () => {
    if (is_mobile) return;

    return (
      <div
        className="flex justify-center items-center 
        h-full w-full        
        lg:px-[60px] lg:py-[20px] xs:px-[0px] xs:py-[0px]
        "
      >
        <img
          src="/asset/background.svg"
          alt="imagen"
          className="h-auto w-full "
        />
      </div>
    );
  };

  const _createAside = () => {
    return (
      <aside
        className="lg:w-3/3 md:w-3/3 sm:3/3 xs:w-full
                    h-full
                    lg:text-left md:text-left sm:text-left xs:text-center
                    lg:px-[20px] md:px-[20px] sm:px-[20px] xs:px-[4px]
                    flex flex-col justify-center"
      >
        <h1
          className=" h-auto home-h2 text-white 
            font-bold
            lg:py-[32px] md:py-[18px] sm:py-[16px] xs:py-[20px]
            lg:text-h1_lg md:text-h1_md sm:text-h1_sm xs:text-h3_lg
            tracking-tight"
        >
          {t("title_home")}
        </h1>
        <span className="text-[150px] hidden xs:inline ">🚀</span>
        <div
          className="flex flex-col justify-start items-start gap-4
          text-white 
          lg:px-[10px]
          lg:py-[32px] md:py-[18px] sm:py-[16px] xs:py-[16px]
          lg:text-p_lg md:text-p_md sm:text-p_md xs:text-p_md"
        >
          <p className="w-full font-bold">{t("subtitle_home_1")}</p>
          <p className="w-full">{t("subtitle_home_2")}</p>
          <button
            className="p-3 rounded-full hover:bg-[#f8c201] hover:text-black font-bold xs:hidden"
            style={{ border: "2px solid grey" }}
            onClick={() =>
              (window.location.href =
                "mailto:business@tech-house.tech?subject=Hola, TechHouse")
            }
          >
            {t("text_button_talk")}
          </button>
        </div>
      </aside>
    );
  };

  return _build();
};

export default Home;
