// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Translation files
import enTranslation from "../locales//en/translation.json";
import esTranslation from "../locales/es/translation.json";

// Initialize i18next
i18n
  .use(LanguageDetector) // Detects language from the browser
  .use(initReactI18next) // Integrates with React
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
    },
    fallbackLng: "es", // Default language if detection fails
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator"],
      caches: ["localStorage", "cookie"],
    },
  });

export default i18n;
