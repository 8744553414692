import "./App.css";
import WhoUs from "./pages/whous/WhoUs";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import JoinUs from "./pages/join-us/JoinUs";
import Contact from "./pages/contact/Contact";
import Team from "./pages/team/Team";
import Layout from "./components/layout/layout";
import Services from "./pages/services/Services";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  return (
    <main
      id="version: 1.0.1"
      className="flex flex-col justify-start items-center h-auto m-auto w-full overflow-x-hidden"
    >
      <Layout children={<Home />} addClass={"bg-[#282c31]"} />

      <Layout
        children={
          <>
            <WhoUs t={t} />
            <Services t={t} />
            <About t={t} />
            <JoinUs t={t} />
            <Team t={t} />
          </>
        }
      />

      <Layout children={<Contact t={t} />} addClass={"bg-[#282c31]"} />
    </main>
  );
}

export default App;
