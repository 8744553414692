import React from "react";
import CardWithImage from "../../components/services/CardWithImage";

const Services = ({ t }) => {
  return (
    <section className="flex flex-col  h-auto xs:h-auto xs:min-h-screen w-full xs:pt-7">
      <article className="flex flex-row w-full h-1/2 xs:grow">
        <aside className="flex flex-col justify-center items-center w-1/2 h-full xs:hidden md:p-5 ">
          <img
            src="/asset/join-us.svg"
            alt="img-whoUs"
            style={{ height: "100%", width: "100%" }}
          />
        </aside>
        <div
          className="flex flex-col justify-center items-start gap-[10px] h-auto
              w-1/2  sm:w-full xs:w-full xs:justify-start xs:pt-7 pl-7 
              xs:px-[15px] xs:grow"
        >
          <h2 className="title py-[10px]">{t("section_services_title")}</h2>
          <p
            className="flex flex-col justify-center items-left gap-[12px]  
                            font-bold lg:text-h2_md md:text-h3_xs sm:text-h2_sm xs:text-h2_xs"
          >
            {t("section_services_description")}
          </p>

          <div className="h-auto w-full justify-center items-center hidden pt-[24px] xs:hidden">
            <img
              src="/asset/join-us.svg"
              alt="JoinUs"
              className=""
              style={{ maxHeight: "200px", width: "200px" }}
            />
          </div>
        </div>
      </article>
      <footer className="flex flex-row justify-between items-start w-full h-auto xs:grow xs:flex-col lg:pt-5 xs:gap-3 xs:rounded-sm">
        <CardWithImage
          img={"/asset/icon-service-2.svg"}
          title={"Head hunting"}
          description={t("section_services_card_1")}
        />

        <CardWithImage
          img={"/asset/icon-service-1.svg"}
          title={"Staff Augmentation"}
          description={t("section_services_card_2")}
        />
        <CardWithImage
          img={"/asset/icon-service.svg"}
          title={"Dedicated Team"}
          description={t("section_services_card_3")}
        />
      </footer>
    </section>
  );
};

export default Services;
